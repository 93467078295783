<template>
  <transition name="fade">
    <div v-if="!state.loaderEnabled">
      <div class="card" id="kt_modal_create_edit_site" ref="createEditSiteRef" tabindex="-1" aria-hidden="true" key='state.rerender'>
        <div class="">
          <div class="modal-content" style='background: none;'>
            <div class="modal-header bg-white">
              <h2>{{ state.titleModal }}</h2> 


                <div class="d-flex flex-stack">
                    <div class="me-2" v-if="state.step != 1">
                      <button type="button" class="btn btn-lg btn-light-primary me-3" @click="previousStep()">
                      <span class="svg-icon svg-icon-3 me-1"><inline-svg src="media/icons/duotune/arrows/arr063.svg" /> </span> Retour </button>
                    </div>
                    <div class="me-2" v-if="state.step == 1">
                        <button type="button" class="btn btn-lg btn-light-danger me-3" @click="retourTicket">
                        <span class="svg-icon svg-icon-3 me-1"><inline-svg src="media/icons/duotune/arrows/arr063.svg" /> </span> Retour à la liste de vos tickets</button>
                    </div>
                    <div class="me-2" v-else></div>
                    <div v-if="!state.loaderCreation">
                      <button @click="nextStep()" class="btn btn-lg btn-primary" v-if="state.stepCode!='last'">
                        Continuer
                        <span class="svg-icon svg-icon-3 ms-1 me-0">
                          <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                        </span>
                      </button>
                      <button @click="createTicket()" class="btn btn-lg btn-primary" v-else>
                        Création du ticket
                        <span class="svg-icon svg-icon-3 ms-1 me-0">
                          <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                        </span>
                      </button>
                    </div>
                </div>

            </div>
            <div class="modal-body scroll-y m-5" style="padding: 0;  margin: 0 !important;">

              <div class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_account_stepper" ref="createAccountRef">
                <div class="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100" style="background: #ffffff; padding-left: 12px; box-shadow: rgb(0 0 0 / 68%) 6px 0px 7px -8px; width: 330px !important;">
                  <div class="stepper-nav ps-lg-10" style="padding-left: 0 !important;  padding-top: 40px; margin-left: 15px;">
                    <template  v-for="(item, i) in state.etapes" :key="i">

                      <div class="stepper-item" data-kt-stepper-element="nav" :class="{current: state.step==i+1}" v-if="item.visible">
                        <div class="stepper-line w-40px"></div>
                        <div class="stepper-icon w-40px h-40px">
                          <i class="stepper-check fas fa-check"></i>
                          <span class="stepper-number">{{ i+1 }}</span>
                        </div>
                        <div class="stepper-label">
                          <h3 class="stepper-title">{{ item.title }}</h3>
                          <div class="stepper-desc">
                            {{ item.desc }}</div>
                        </div>
                      </div>
                    </template>                
                  </div>
                </div>


                <div class="flex-row-fluid py-lg-0 px-lg-15" style="min-height: calc(100vh - 372px); padding: 40px !important;"> 
                  <div class="form" novalidate="novalidate">
                  
                    <div class="current" data-kt-stepper-element="content" v-if="state.stepCode=='info'">
                      <div class="w-100">
                        <div class="pb-10 pb-lg-10">
                          <h2 class="fw-bolder d-flex align-items-center text-dark">Informations principales sur le ticket</h2>
                        </div>

                        <div class="mb-10 fv-row">
                          <label class="form-label mb-3">Libellé du ticket</label>
                          <el-input size="large" v-model="state.formData.tic_c_label" placeholder="Facultatif, si vous le souhaitez, vous pouvez nommer ce ticket." />
                        </div>
                        <div class="mb-0 fv-row">
                          <label class="d-flex align-items-center form-label mb-5">Veuillez sélectionnez un ou plusieurs types de services</label>
                          <div class="mb-5 bg-white p-8">

                            <template v-for="(item, index) in state.formData.tic_c_serv" :key="index">
                              <label class="d-flex flex-stack mb-9 cursor-pointer"  v-if="item.visible">
                                <span class="d-flex align-items-center me-2">
                                  <span class="symbol symbol-50px me-6">
                                    <span class="symbol-label">
                                      <span class="svg-icon svg-icon-1 svg-icon-primary"><inline-svg :src="`media/icons/duotune/${item.svg}`" /></span>
                                    </span>
                                  </span>
                                  <span class="d-flex flex-column">
                                    <span class="fw-bolder text-gray-800 text-hover-primary fs-5">{{ capitalize(item.name) }}</span>
                                    <span class="fs-6 fw-bold text-gray-400">{{ item.subtitle }}</span>
                                  </span>
                                </span>
                                <label class="form-check form-switch form-check-custom form-check-solid">
                                  <input class="form-check-input" type="checkbox" v-model="state.formData.tic_c_serv[index].xCheckUser" />
                                </label>
                              </label>
                            </template>


                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="current" data-kt-stepper-element="content" v-if="state.stepCode=='lstMat'">
                      <div class="w-100">
                        <div class="pb-10 pb-lg-10">
                          <h2 class="fw-bolder d-flex align-items-center text-dark"> Liste du matériel </h2>
                          <div class="text-gray-400 fw-bold fs-6">
                            Veuillez spécifier les quantités à expédier sur vos produits.
                          </div>
                        </div>

                            <div
                              v-if="state.myListStock.length == 0" class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10">
                              <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
                                <inline-svg src="media/icons/duotune/general/gen048.svg" />
                              </span>
                              <div class="d-flex flex-column">
                                <h4 class="mb-1 text-dark">Aucun équipement de stock</h4>
                                <span>Il n'existe aucun équipement de stock à afficher correspondant à vos critères de recherche.</span>
                              </div>
                            </div>
                            <div class="bg-white p-8 text-end-left">
                              <Datatable
                                v-if="state.myListStock && state.myListStock.length > 0"
                                :table-data="state.myListStock"
                                :table-header="tableHeader"
                                :enable-items-per-page-dropdown="true"
                                empty-table-text="Aucune ligne à afficher."
                              >
                                <template v-slot:cell-loc_reference="{ row: data }">
                                  <div class="text-gray-800"  style="margin-left: 5px; min-width: 300px;">
                                    <div class="text-gray-400">
                                      <span class="w-150px  fs-6 text-dark">{{ data.loc_reference }}</span>
                                    </div>
                                    <div class="fs-7 text-gray-500" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">{{ data.loc_designation }}</div>
                                  </div>
                                  <span v-html="data.site" class="badge badge-secondary fs-7 text-gray-700 mt-2"></span>
                                </template>
                                <template v-slot:cell-toc_code="{ row: data }">
                                  <div v-for="(item, index) in data.toc_code" :key="index">                                    
                                    <el-popover placement="right" :width="320" trigger="hover" :content="`Commande Stillnetwork : ${item.numcmd}`" :show-arrow="true">
                                       <template #reference>
                                        <el-checkbox @change="recalculStock(data)" v-model="state.myListStock[
                                            state.myListStock.findIndex(el => el.loc_id == data.loc_id)
                                          ].toc_code[state.myListStock[state.myListStock.findIndex(el => el.loc_id == data.loc_id)]
                                          .toc_code.findIndex(el => el.code == item.code)].select" 
                                        size="small" :label="item.code" />
                                      </template>
                                    </el-popover>
                                    <br>
                                  </div>
                                </template>
                                <template v-slot:cell-qte="{ row: data }">
                                  <span class="badge  w-200px fs-6 text-gray-900" style="text-align:left;">
                                    <span v-if="data.qte>0" class="text-dark fs-5">{{ data.qte }} <span class="fs-8 text-gray-400" v-if="data.date_liv">&nbsp;&nbsp;échéance au {{ formatDate(data.date_liv) }}</span> <span class="fs-8 text-gray-400" v-if="!data.date_liv"> actuellement disponible.</span> </span>
                                    <span v-else class="text-danger fs-7">Aucun stock</span>
                                  </span>
                                  <br>
                                  <span v-if="data.reappro>0" class="badge w-200px fs-6 text-gray-900 mt-2" style="text-align:left;">
                                    + {{ data.reappro }} 
                                    <span v-if="data.date_reappro" class="fs-8 text-gray-400">en réappro. dés le {{ formatDate(data.date_reappro) }}</span>
                                    <span v-else class="text-gray-400">(date en attente)</span>
                                  </span>
                                </template>

                                <template v-slot:cell-loc_id="{ row: data }">
                                  <label class="form-check form-check-custom form-check-solid" style="padding-right: 30px; width : 165px">
                                    <input  type="number" v-model="data.qte_exped" :min="0" :max="data.qte_max" v-if="data.qte_max>0" controls-position="right" @change="handleChangeQte(data.qte_exped, data.loc_id)" 
                                            style="width: 100%; background-color: #ffffff; border: 1px solid #dcdfe6; border-radius: 4px;  padding: 7px; color: #5E6278;text-align: center; padding-left: 20px" 
                                            :class="{ 'bg-light-success text-dark' : data.qte_exped>0 }" />
                                  </label>
                                </template>
      
                              </Datatable>
                            </div>

                      </div>
                    </div>


                    <div v-if="state.stepCode=='exped'">
                      <div class="w-100">
                        <div class="pb-8 pb-lg-10">
                          <h2 class="fw-bolder text-dark">Expédition</h2>
                          <div class="text-gray-400 fw-bold fs-6">
                            Quelques informations sont requises afin d'assurer la sortie du stock.
                          </div>
                        </div>


                        <div class="text-gray-800 fw-bold fs-6 mb-5">
                          Concernant l'expèdition en elle même, vous souhaitez :
                        </div>

                        <el-radio-group v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].type_exped" class="ml-4 mb-10 px-5 pt-5">
                          <el-radio class='mb-3' :label="0" size="large">Envoyer le matériel dés que possible</el-radio><br>
                          <el-radio class='mb-3' :label="1" size="large">Envoyer le matériel à partir d'une date</el-radio><br>
                          <el-radio class='mb-3' :label="2" size="large">Bloquer le stock de matériel pour une livraison future</el-radio>
                        </el-radio-group>

                        <template v-if="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].type_exped == 1">
                          <div class="text-gray-800 fw-bold fs-6 mb-5 mt-3" >
                            Veuillez choisir la date d'expédition ci-dessous
                          </div>
                          <el-row :gutter="10">
                            <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                              <div class="bg-white p-8">
                                <el-date-picker v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].date1"  type="date"  placeholder="choix de la date"  class="w-100p w-100" size="large" /><br>
                              </div>
                            </el-col>
                          </el-row>
                        </template>

                        <div class="text-gray-800 fw-bold fs-6 mb-5 mt-10">
                          Avez-vous une precision à apporter à notre équipe gérant le stock ?
                        </div>
                        <div class="mb-0">
                          <div class="mb-5">
                            <el-input
                              v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].desc_globale"
                              :autosize="{ minRows: 6, maxRows: 12}"
                              type="textarea"
                              placeholder="Type d'intervention, nombre de jours, compétences techniques... "
                            />
                          </div>
                        </div>


                      </div>
                    </div>


                    <div v-if="state.stepCode=='lieu'">
                      <div class="w-100">
                        <div class="pb-10 pb-lg-12">
                          <h2 class="fw-bolder text-dark">Adresse et Contact</h2>
                          <div class="text-gray-400 fw-bold fs-6">
                            Veuillez parcourir les onglets ci-dessous afin de spécifier les adresses et contacts.
                          </div>
                        </div>

                        <el-tabs @tab-click="handleClick" v-model="state.activeLieu" size="large" >
                          <template v-for="(item, index) in (state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.xlieu == true))" :key="index">
                            <el-tab-pane :label="`${index==0 ? 'Adresse principale (' + item.name + ')': 'Adresse ' + (item.code!='custom' ? 'de ':'') + item.name}`" :name="item.code" style='font-size: 1.4rem;'></el-tab-pane>  
                          </template>
                        </el-tabs>

                        <div style="padding: 35px;background: white;">

                          <template v-if="testFirstLieu() != state.activeLieu">
                            <div class="text-gray-800 fw-bold fs-6 mb-4">
                              L'adresse concernant {{  state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].denom + state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].name }} est-elle identique à l'adresse principale ?
                            </div>

                            <el-radio-group v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu_is_princ" class="ml-4 mb-10">
                              <el-radio-button :label="true" size="large">Oui, c'est la même adresse</el-radio-button>
                              <el-radio-button :label="false" size="large">Non, l'adresse est différente.</el-radio-button>
                            </el-radio-group>
                          </template>

                          <template v-if=" testFirstLieu() == state.activeLieu || !state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu_is_princ ">
                          
                            <div class="mb-10 fv-row p-8 bg-light-primary" v-show="state.mySocieteList.length > 1" >
                              <label class="form-label mb-3">Affichage des sites de la société</label>     

                              <select name="status" data-control="select2" data-hide-search="true" class="form-select  w-400px" style="border:1px solid #dcdfe6" 
                                      v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe"
                                      @change="changeSocieteSite()">

                                <option v-for="(item, index) in state.mySocieteList" :key="index" :value="item.soc_seq" >{{ capitalize(item.soc_nom_societe) }}</option>

                              </select>
                            </div>

                            <div v-if="state.loaderTableList" class="text-gray-800 fw-bold fs-6"> Veuillez sélectionner une adresse et un contact dans la liste suivante :</div>

                            <div class="text-end-left">
                              <Datatable v-if="state.mySiteList && state.mySiteList.length > 0 && state.loaderTableList" :key="state.mySiteList || state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)]" 
                                :table-data="state.mySiteList" :table-header="tableHeaderSite" 
                                :enable-items-per-page-dropdown="true" empty-table-text="Aucun site à afficher." @selectRows="selectRows">
                                <template v-slot:cell-status="{ row: data }" >
                                  <span class="form-check form-check-custom form-check-solid" ><input class="form-check-input" type="radio" :value="data.adr_seq"
                                        :key="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)]"  
                                        v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu" /></span>
                                </template>
                                <template v-slot:cell-adr_seq="{ row: data }">
                                  <span class="text-primary badge badge-light-primary px-2 bt-5" style="min-width: 100px; text-align:left; text-transform: uppercase" v-if="data.adr_c_site_label">{{ data.adr_c_site_label }}</span>
                                  <span class="text-danger" v-else> <div class="badge badge-light px-2 bt-5" style="min-width: 100px; text-align:left; text-transform: uppercase"> Aucun libellé </div> </span>
                                </template>
                                <template v-slot:cell-adr_ligne_1="{ row: data }">
                                  <span class="text-gray-700 fs-7" v-if="data.adr_ligne_1"> {{ data.adr_ligne_1 }}</span> <span class="text-gray-700 fs-7 " v-if="data.adr_ligne_2"> - {{ data.adr_ligne_2 }}</span><br>
                                  <span class="text-gray-700 fs-7">{{ data.adr_cp }} - {{ data.adr_ville }}</span><span class="text-gray-700 fs-7" v-if="data.adr_pays"> - {{ data.adr_pays }} </span>
                                </template>
                                <template v-slot:cell-contacts="{ row: data }">
                                  <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-sm  w-200px" style="border:1px solid #dcdfe6"
                                          v-show="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu == data.adr_seq"
                                          v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_contact"
                                  >
                                    <option v-for="(item, index) in state.listContacts" :key="index" :value="item.con_seq">{{ capitalize(item.con_prenom) }} {{ upperCase(item.con_nom) }}</option>
                                  </select>
                                </template>
                              </Datatable>
                            </div>

                          </template>



                        </div>

                      </div>
                    </div>


                    <div  v-if="state.stepCode=='deploy' || state.stepCode.indexOf('custom')>=0 ">
                      <div class="w-100">
                        <div class="pb-8 pb-lg-10">
                          <h2 class="fw-bolder text-dark"  v-if="state.stepCode=='deploy'">Déploiement</h2>
                          <h2 class="fw-bolder text-dark" v-else>Service Spécifique {{ state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].name }}</h2>
                          <div class="text-gray-400 fw-bold fs-6">
                            Afin de vous offir la meilleure prestation, nous avons besoin d'informations spécifiques.
                          </div>
                        </div>
                        <div class="text-gray-800 fw-bold fs-6 mb-10">
                          Pourriez-vous décrire les élements souhaités <span v-if="state.stepCode=='deploy'">pour la prestation d'ingénierie </span>?
                        </div>
                        <div class="mb-0">
                          <div class="mb-5">
                            <el-input
                              v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].desc_globale"
                              :autosize="{ minRows: 6, maxRows: 12}"
                              type="textarea"
                              placeholder="Type d'intervention, nombre de jours, compétences techniques... "
                            />
                          </div>
                        </div>
                        <div class="text-gray-800 fw-bold fs-6 mb-10 mt-10">
                          Quand souhaiteriez-vous que nous programmions l'intervention ?
                        </div>

                        <el-row :gutter="10">
                          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                            <div class="bg-white p-8">
                              <div class="text-gray-600 fw-bold fs-6 mb-2">
                                Première date privilégiée :
                              </div>
                              <el-date-picker v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].date1"  type="date"  placeholder="Premier choix de date"  class="w-100p w-100" size="large" /><br>
                            </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                            <div class="bg-white p-8">
                              <div class="text-gray-600 fw-bold fs-6 mb-2">
                                Deuxième date :
                              </div>
                              <el-date-picker v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].date2"  type="date"  placeholder="Second choix de date"  class="w-100p w-100" size="large" /><br>
                            </div>
                          </el-col>
                          <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                            <div class="bg-white p-8">
                              <div class="text-gray-600 fw-bold fs-6 mb-2">
                                Troisième date :
                              </div>
                              <el-date-picker v-model="state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].date3"  type="date"  placeholder="Dernier choix de date"  class="w-100p w-100" size="large" /><br>
                            </div>
                          </el-col>
                        </el-row>

                        <div class="text-gray-800 fw-bold fs-6 mb-10 mt-10">
                          Existe-t-il des fichiers à nous transmettre en lien avec cette prestation ?
                        </div>
                        <div class="bg-white p-8">
                          <div class="text-gray-600 fw-bold fs-6 mb-2">
                            <input type="file" multiple :id="`${state.stepCode+'_'+state.stepCode}`" @change="handleChangeUpload(state.stepCode,state.stepCode)" style='display: none;'> 
                            <el-button type="primary" size="small" @click="openUpload(state.stepCode+'_'+state.stepCode)" >Ajouter un fichier</el-button>
                            <template v-for="(item, index) in state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].fichiers" :key="index">
                              <transition name="slide-fade">
                                <a @click="deleteFile(state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].fichiers, index)" 
                                  class="btn btn-sm btn-white btn-active-light-danger px-4 py-2 me-4 mt-1 mb-1 btn-upload-custom"  style='width: 100% !important;'
                                >
                                  <i class="las la-trash fs-2 me-2"></i>
                                  Fichier : {{ item.name }}
                                </a>
                              </transition>
                            </template>
                          </div>
                        </div>

                      </div>
                    </div>


                    <div v-if="state.stepCode=='staging'">
                      <div class="w-100">
                        <div class="pb-8 pb-lg-10">
                          <h2 class="fw-bolder text-dark">Staging</h2>
                          <div class="text-gray-400 fw-bold fs-6">
                            Transmettez-nous vos fichiers des configurations et les informations spécifiques sur vos matériels
                          </div>
                        </div>
                        <div class="text-gray-400 fw-bold fs-6 mb-10">
                          
                            <div class="bg-white p-8">
                              <div class="text-end-left">
                                <Datatable
                                  v-if="state.formData.tic_staging && state.formData.tic_staging.length > 0"
                                  :table-data="state.formData.tic_staging"
                                  :table-header="tableHeaderStaging"
                                  :enable-items-per-page-dropdown="true"
                                  empty-table-text="Aucune ligne à afficher."
                                >
                                  <template v-slot:cell-loc_id="{ row: data }">
                                    <div class="text-gray-800"  style="margin-left: 5px; min-width: 300px;">
                                      <div class="text-gray-400">
                                        <span class="w-150px  fs-6 text-dark">{{ data.loc_reference }}</span>
                                      </div>
                                      <div class="fs-7 text-gray-500" style="display: -webkit-box;-webkit-line-clamp: 1; -webkit-box-orient: vertical;  overflow: hidden;  text-overflow: ellipsis;">{{ data.loc_designation }}</div>
                                    </div>
                                  </template>
                                  <template v-slot:cell-fichiers="{ row: data }">
                                      <input type="file" multiple :id="`${data.loc_reference+'_'+data.loc_id}`" @change="handleChangeUpload(data.loc_reference,data.loc_id)" style='display: none;'> 
                                      <el-button type="primary" size="small" @click="openUpload(data.loc_reference+'_'+data.loc_id)" >Ajouter un fichier</el-button>
                                      <template v-for="(item, index) in state.formData.tic_staging[state.formData.tic_staging.findIndex(element => element.loc_reference == data.loc_reference && element.loc_id == data.loc_id)].fichiers" :key="index">
                                        <transition name="slide-fade">
                                          <a @click="deleteFile(state.formData.tic_staging[state.formData.tic_staging.findIndex(element => element.loc_reference == data.loc_reference && element.loc_id == data.loc_id)].fichiers, index)" 
                                            class="btn btn-sm btn-white btn-active-light-danger px-4 py-2 me-4 mt-1 mb-1 btn-upload-custom" 
                                          >
                                            <i class="las la-trash fs-2 me-2"></i>
                                            Fichier : {{ item.name }}
                                          </a>
                                        </transition>
                                      </template>
                                  </template>
                                  <template v-slot:cell-commentaires="{ row: data }">
                                    <el-input
                                      v-model="state.formData.tic_staging[state.formData.tic_staging.findIndex(element => element.loc_reference == data.loc_reference && element.loc_id == data.loc_id)].commentaires"
                                      :autosize="{ minRows: 2, maxRows: 20 }"
                                      type="textarea"
                                      class="coms-staging"
                                      placeholder="Commentaires spécifique..."
                                    />
                                  </template>      
                                </Datatable>
                              </div>
                            </div>


                        </div>
                      </div>
                    </div>


                    <div v-if="state.stepCode=='last'">
                      <div class="w-100">
                        <div class="pb-8 pb-lg-10">
                          <h2 class="fw-bolder text-dark">Synthèse et étapes du ticket</h2>
                        </div>
                        <div class="text-gray-400 fw-bold fs-6 mb-10">
                          
                          <div style="width: 100%; height: 500px; text-align: center; padding-top: 100px; padding-bottom: 100px" v-if="state.loaderCreation">
                              <div class="lds-ring"><img src="media/logos/Logo-Symbole-couleur.svg"/><div></div><div></div><div></div><div></div></div>
                          </div>

                          <affTicket :objTicket="state.formData" v-if="!state.loaderCreation" />

                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script lang="ts">
import { defineComponent, onMounted, reactive, watch, ref} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import affTicket from "@/views/still/deploy/affTicket.vue";

import * as moment from "moment";

export default defineComponent({
  name: "create-edit-site",
  components: {
    Datatable,
    affTicket
  },
  props: {
    mCode: String,
  },
  setup() {
    moment.default.locale("fr");

    const store = useStore();
    const mSoc_seq = store.getters.currentUser.con_knum_societe;

    const router = useRouter();

    const state = reactive({
      myListStock: [] as any,
      mySiteList: [],
      myContactList: [],
      mySocieteList : [] as any,
      initialMyList: [],
      loaderEnabled : true,
      loaderCreation : false,
      loadingDatatable: 0,
      loaderTableList : false,
      search: "",

      idTicket: 0,      
      titleModal : 'Création d\'un nouveau ticket',
      subTitle : 'Création du ticket',
      etapes : [] as any,

      listContacts: [] as any,
      activeLieu : 'factu',

      formData : {
        tic_launch:0,
        tic_knum_societe : 0,
        tic_c_label:'',
        tic_c_serv : [
          /*
          {code:'validation', denom : '', name: 'validation', subtitle:'', type_exped : 0, knum_societe : mSoc_seq, knum_contact : 0, xlieu : false, knum_lieu : 0, xCheckUser : true, svg: "maps/map009.svg", xMateriel : true, knum_lieu_is_princ: true, visible: false, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'staging', denom : 'le ', name: 'staging',  subtitle:'Transmettez-nous votre configuration matériel',knum_societe : mSoc_seq, knum_contact : 0,  xlieu : false, knum_lieu : 0, xCheckUser : false, svg: "technology/teh001.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'exped', denom : 'l\'', name: 'expédition', subtitle:'Procédez à une sortie de stock et lancer l\'expédtion de vos produits', type_exped : 0, knum_societe : mSoc_seq, knum_contact : 0, xlieu : true, knum_lieu : 0, xCheckUser : true, svg: "maps/map009.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'deploy', denom : 'le ', name: 'déploiement',  subtitle:'Faites une demande de prestations d\'ingénierie qualifié',knum_societe : mSoc_seq, knum_contact : 0,  xlieu : true, knum_lieu : 0, xCheckUser : false, svg: "technology/teh005.svg", xMateriel : false, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          {code:'custom', denom : '', name: '', subtitle: 'Service spécifique', xlieu : true,knum_societe : mSoc_seq, knum_contact : 0, knum_lieu : 0, xCheckUser : false, svg: "abstract/abs026.svg", xMateriel : true, knum_lieu_is_princ: true, visible: true, desc_globale : '', date1:'', date2:'', date3:'', fichiers: [] as any},
          {code:'factu', denom : 'la ', name: 'facturation', subtitle: '', xlieu : true,knum_societe : mSoc_seq, knum_contact : 0, knum_lieu : 0, xCheckUser : true, svg: "", xMateriel : false, knum_lieu_is_princ: true, visible: false, desc_globale : '', date1:'', date2:'', date3:'', fichiers: []  as any},
          */
        ] as any,
        tic_list_matos : [] as any,
        tic_staging : [] as any
      },
      totStep : 1,
      step : 1,
      stepCode : 'info',
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const tableHeader = ref([
      { name: "Equipement", key: "loc_reference",  sortable: false,  },
      { name: "Quantité Disponible", key: "qte", sortable: false, },
      { name: "Commandes", key: "toc_code", sortable: false, },
      { name: "Saisir quantité", key: "loc_id", sortable: false, },
    ]);

    const tableHeaderStaging = ref([
      { name: "Equipement", key: "loc_id",  sortable: false,  },
      { name: "Fichiers", key: "fichiers", sortable: false, },
      { name: "Commentaires", key: "commentaires", sortable: false, },
    ]);

    const tableHeaderSite = ref([
      { name: "", key: "status", sortable: false, },
      { name: "Libellé", key: "adr_seq", sortable: false, },
      { name: "Adresse", key: "adr_ligne_1", sortable: false, },
      { name: "Contact", key: "contacts", sortable: false, },
    ]);

    const selectRows = (item) => {
      state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_lieu = item.adr_seq
    }

    const refreshSites = () => {
      state.loadingDatatable += 1;
    }
    
    watch(state.formData, () => {
      
      if (!state.loaderTableList) return false;

      state.etapes = [];
      state.etapes.push({code:'info', title: 'informations', desc: 'Généralités sur le ticket', visible : true});
           

      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xMateriel == true )) state.etapes.push({code:'lstMat', title: 'Liste du matériel', desc: 'Quel matériel est concerné ?', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "staging" )) state.etapes.push({code:'staging', title: 'Staging', desc: 'Configuration du matériel', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "exped" )) state.etapes.push({code:'exped', title: 'Expédition', desc: 'Organisez la sortie de matériels', visible : true});
      if (state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.code == "deploy" )) state.etapes.push({code:'deploy', title: 'Déploiement', desc: 'Prestations d\'ingénierie', visible : true});
      
      state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.code.indexOf("custom") >= 0 ).forEach(myElement => {
        state.etapes.push({code:myElement.code, title: myElement ? myElement.name : '', desc: 'Service spécifique', visible : true});
      });

      state.etapes.push({code:'lieu', title: 'Adresse et contact', desc: 'Sélection de site(s)', visible : true});
      state.etapes.push({code:'last', title: 'Synthèse du ticket', desc: 'Et création de votre ticket.', visible : true});

      if (state.stepCode!='lieu') {
        const activeLieu = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
        state.activeLieu = activeLieu ? activeLieu.code : 'factu';
        //console.log("AAA", state.formData.tic_c_serv);
        //console.log("AAAAA", state.activeLieu);
        if (state.mySocieteList.length > 0) state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe = state.mySocieteList[0].soc_seq;
      }
      
      //if (activeLieu) activeLieu.knum_lieu_is_princ = true;

    });

    onMounted(() => {
       addSite();
    });
   
    const formSubmit = () => {
      const sendData = state.formData;
        mAxiosApi
          .post("addupdateSite", { sendData })
          .then( async () => {
              Swal.fire({
                text: 'Opération réalisée avec succés !',
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Retour à la liste des tickets",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(() => {
                router.push({  name: "ticket" });
              });
          })
          .catch((err) => {
            console.error(err);
          });

    };

    const previousStep = () => {
      state.step -= 1;
      state.stepCode = state.etapes[state.step-1].code;

    }
    const nextStep = async () => {

      if (state.etapes[state.step-1].code == "info") {
        const item = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.name == '')
        if (item !== undefined) {
          ElMessage({showClose: true,  message: 'Attention, le champ ' +  item.subtitle + ' ne peut être vide.',  type: 'error', })
          return false;
        }
        const first = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
      }

      if (state.etapes[state.step-1].code == "exped") {
        const type_exped = state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].type_exped;
        if (type_exped == 1 && !state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == 'exped')].date1) {
          ElMessage({showClose: true,  message: 'Attention, vous devez saisir une date.',  type: 'error', })
          return false;
        }

      }

      if (state.etapes[state.step].code == "last") {
        const itemsLieu = state.formData.tic_c_serv.filter(element => element.xCheckUser == true && element.xlieu == true);

        // console.log(itemsLieu);

        if (itemsLieu[0].knum_lieu == 0) {
          ElMessage({showClose: true,  message: 'Attention, vous devez sélectionner une adresse principale.',  type: 'error', })
          return false;
        }


        let count = 0;
        let socDefaut = 0;
        let LieuDefaut = 0;
        let ContactDefaut = 0;

        itemsLieu.forEach((val) => {
          if (count != 0) {
            if (val.knum_lieu_is_princ) {
              val.knum_lieu = LieuDefaut;
              val.knum_societe = socDefaut;
              val.knum_contact = ContactDefaut;
            }            
          } else {
              LieuDefaut = val.knum_lieu;
              socDefaut = val.knum_societe;
              ContactDefaut = val.knum_contact;
          }
          count += 1;

        });       
      }


      
      if (state.etapes[state.step-1].code == "lstMat") {

        const item = state.myListStock.filter(element => element.qte_exped >0)

        let error = "";
        item.forEach((val) => {  
          let SearchAllNotSelected = val.toc_code.filter(element => element.select == true);
          if (SearchAllNotSelected.length == 0) {
            error = 'Attention, aucune reférence commande n\'a été sélectionnée pour la référence ' +  val.loc_reference;
            return false;
          }
        });
        if (error.length > 0) {
            ElMessage({showClose: true,  message: error,  type: 'error', })
            return false;
        }

        if (item.length == 0) {
          ElMessage({showClose: true,  message: 'Attention, aucune quantité n\'a été saisie.',  type: 'error', })
          return false;
        }

        // Build liste staging
        item.forEach((val) => {
          let count = 0;
          for (let i = 0; i < val.qte_exped; i++) {
            
            const exist = state.formData.tic_staging.find(element => element.loc_id == i && element.loc_reference == val.loc_reference);
            if (!exist) {
              state.formData.tic_staging.push({ 
                  loc_id: i,
                  loc_reference: val.loc_reference,
                  loc_toc_seq : val.toc_code,
                  loc_designation: val.loc_designation,
                  date_reappro: val.date_reappro,
                  fichiers: [] as any,
                  commentaires : '',
              });
            }
            count += 1;
          }
          const OtherItems = state.formData.tic_staging.filter(element => element.loc_id >= count && element.loc_reference == val.loc_reference);
          OtherItems.forEach(f => state.formData.tic_staging.splice(state.formData.tic_staging.findIndex(e => e.loc_id === f.loc_id),1));

        });

        // Delete orphelin
        const orph = state.myListStock.filter(element => element.qte_exped == 0)
        orph.forEach((val) => {
          const OtherItems = state.formData.tic_staging.filter(element => element.loc_reference == val.loc_reference);
          OtherItems.forEach(f => state.formData.tic_staging.splice(state.formData.tic_staging.findIndex(e => e.loc_id === f.loc_id),1));
        });

        state.formData.tic_staging.sort(function (a, b) {
            let x = a.loc_reference.toUpperCase(),
                y = b.loc_reference.toUpperCase();
            return x == y ? 0 : x > y ? 1 : -1;
        });

        state.formData.tic_list_matos = state.myListStock.filter(element => element.qte_exped >0);

      }

      state.stepCode = state.etapes[state.step].code;
      state.step += 1;
    }

    const openUpload = (id) => {
      const file1: any = document.querySelector('input[id='+id+']');
      file1.click();
    }
    const handleChangeUpload= (loc_reference, loc_id) => {
      const identif = loc_reference+'_'+loc_id;
      const file1: any = document.querySelector('input[id='+identif+']');
      const file = file1.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e) {

          if (file.size/1024/1024 > 10) {
            ElMessage({showClose: true,  message: 'Attention, la taille du fichier ne peut dépasser 10 MO !',  type: 'error', })
            return false 
          }
          
          if(state.stepCode.indexOf('custom') >=0 || state.stepCode=='deploy') {
            if(e.target) state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.stepCode)].fichiers.push({ name: file.name, data: e.target.result });
          }else {
            if(e.target) state.formData.tic_staging[state.formData.tic_staging.findIndex(element => element.loc_reference == loc_reference && element.loc_id == loc_id)].fichiers.push({ name: file.name, data: e.target.result });
          }
        };
      }
    }
    const deleteFile = (list, index) => {
      list.splice(index, 1);
    }

    const resetForm = () => {
      // state.formData = { };
    };

    async function addSite() {
      state.loaderTableList = false;
      state.idTicket = 0;
      state.titleModal = 'Création d\'un nouveau ticket';
      state.subTitle = "Création du ticket";

      state.formData.tic_launch = 1;

      let myListService = await getAxios("/getCatserv");
      //console.log('Befoire Catalogue', myListService);

      myListService.results.forEach((el) => {
        el.cat_json = JSON.parse(el.cat_json);
        el.cat_json.knum_societe = mSoc_seq;
        el.cat_json.code = el.cat_w_code;
        el.cat_json.denom = el.cat_w_denom;
        el.cat_json.name = el.cat_w_name;
        el.cat_json.subtitle = el.cat_w_subtitle;
        el.cat_json.xlieu = parseInt(el.cat_w_xLieu) ? true : false;
        el.cat_json.xMateriel = parseInt(el.cat_w_xMateriel) ? true : false;
        el.cat_json.svg = el.cat_x_svg;
        el.cat_json.visible = parseInt(el.cat_w_visible) ? true : false;
        state.formData.tic_c_serv.push(el.cat_json);

      })
      // console.log('Catalogue', state.formData.tic_c_serv);

      let myListStock = await getAxios("/getstock");
      myListStock.records.forEach((el) => {
        el.qte_exped = 0;
        el.qte_max = parseInt(el.qte) // + parseInt(el.reappro)
        el.qte = parseInt(el.qte);
        el.toc_code = JSON.parse(el.toc_code);
        el.toc_code_all = JSON.parse(el.toc_code_all);
      })
      state.myListStock = myListStock.records;

      let mySocieteList = await getAxios("/getAllSocietes");
      state.mySocieteList = mySocieteList.results;

      let mySiteList = await getAxios("/getSites");
      state.mySiteList = mySiteList.results;

      let myListContact = await getAxios("/getContacts");
      state.listContacts = myListContact.results;

      state.formData.tic_knum_societe = state.mySocieteList[0].soc_seq;

      state.loaderEnabled = false;
      state.loaderTableList = true;

    }

    const recalculStock = (item) => {
      return false;
      /*
      let qte = 0;
      let reappro = 0;
      const toc_code = item.toc_code.filter(element => element.select == true);

      toc_code.forEach((val) => {
        let a_soustraire = 0;
        const toc_code_all = item.toc_code_all.filter(element => element.refCmd == val.numcmd);
        toc_code_all.forEach((val2) => {
            if(a_soustraire == 0 && val2.ticket_cmd > 0) a_soustraire = val2.ticket_cmd;
            qte = qte + val2.qte;
            reappro = reappro + val2.reappro;
        });
        qte = qte - a_soustraire;
      });

      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte = qte;
      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].reappro = reappro;
      state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte_max = qte; // + reappro;

      handleChangeQte(state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].qte_exped, state.myListStock[state.myListStock.findIndex(el => el.loc_id == item.loc_id)].loc_id)
      */
      

    }

    const handleClick = () => {
      changeSocieteSite();
    }

    const testFirstLieu = () => {
      const first = state.formData.tic_c_serv.find(element => element.xCheckUser == true && element.xlieu == true);
      if (first) return first.code;
      return 'factu';
    }

    const changeSocieteSite = async () => {

    state.loaderTableList = false;

      const soc_seq = state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe;
      state.formData.tic_c_serv[state.formData.tic_c_serv.findIndex(element => element.code == state.activeLieu)].knum_societe = soc_seq;
      
      let mySiteList = await getAxios("/getSites/" + soc_seq);
      state.mySiteList = mySiteList.results;

      let myListContact = await getAxios("/getContacts/" + soc_seq);
      state.listContacts = myListContact.results;

      state.loaderTableList = true;
      
    }

    const createTicket = async() => {

      state.loaderCreation = true;

      const form = {
        tic_c_label : state.formData.tic_c_label,
        tic_services : [] as any,
        tic_matos : [] as any,
      }
      const itemsServices = state.formData.tic_c_serv.filter(element => element.xCheckUser == true);
      itemsServices.forEach((val) => {

        form.tic_services.push({
          tis_code : val.code,
          tis_type_exped : val.type_exped ? val.type_exped : 0,
          tis_knum_societe : val.knum_societe,
          tis_knum_contact : val.knum_contact,
          tis_knum_lieu : val.knum_lieu,
          tis_knum_lieu_is_princ : val.knum_lieu_is_princ,
          tis_desc_globale : val.desc_globale,
          tis_date1 : val.date1 ? moment.default(val.date1).format('YYYY-MM-DD') : null,
          tis_date2 : val.date2 ? moment.default(val.date2).format('YYYY-MM-DD') : null,
          tis_date3 : val.date3 ? moment.default(val.date3).format('YYYY-MM-DD') : null,
        })
      });

      state.formData.tic_staging.forEach((val) => {

        let numCmd = '';
        val.loc_toc_seq.filter(el => el.select == true).forEach((val2) => {
          numCmd += val2.toc_seq + ',';
        });
        numCmd = numCmd.slice(0, -1);

        form.tic_matos.push({
          tim_knum_commande : numCmd,
          tim_reference : val.loc_reference,
          tim_designation : val.loc_designation,
          tim_commentaire : val.commentaires,
        })
        
      });

      
      mAxiosApi
        .post("/addTicket", form)
        .then( async(data : any) => {
            
            const allIds = data.data.results;
            const idTicket = allIds.find(el => el.code == 'ticket').seq;

            const itemsServices = state.formData.tic_c_serv.filter(element => element.xCheckUser == true);
            itemsServices.forEach((val) => {
              if (val.fichiers.length > 0) {
                const idService = allIds.find(el => el.code == val.code).seq;

                val.fichiers.forEach((file) => {
                  const formFile = {
                    tif_knum_ticket : idTicket,
                    tif_base64 : file.data,
                    tif_title : file.name,
                    tif_knum_ticket_matos : null,
                    tif_knum_ticket_services : idService
                  }
                  mAxiosApi.post("/addTicket/file", formFile);
                });
              }
            });

            state.formData.tic_staging.forEach((val) => {
              let count = 0;
              const idsMatos = allIds.filter(el => el.code == 'matos');
              // console.log("Matos", idsMatos);

                  //console.log("file",val);
                  //console.log("Matos", idsMatos);

              if (val.fichiers.length > 0) {
                val.fichiers.forEach((file) => {
                  


                  const formFile = {
                    tif_knum_ticket : idTicket,
                    tif_base64 : file.data,
                    tif_title : file.name,
                    tif_knum_ticket_matos : idsMatos[val.loc_id].seq,
                    tif_knum_ticket_services : null
                  }
                  mAxiosApi.post("/addTicket/file", formFile);
                });
              }
              count +=1;
            });

            Swal.fire({
                text: 'Ticket créé avec succés !',
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Retour à la liste des tickets",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then(() => {
                router.push({  name: "ticket" });
            });

        })
        
    }

    const handleChangeQte = (value: number, data) => {
      const found = state.myListStock.find(element => element.loc_id == data);
      found.qte_exped = value;
      if (found.qte_exped > found.qte_max ) found.qte_exped = found.qte_max;
    }

    const capitalize = (word) => {
      if (word) return word[0].toUpperCase() + word.slice(1).toLowerCase();
      return "";
    }

    const upperCase = (word) => {
      if (word) return word.toUpperCase();
      return "";
    }

    const retourTicket = () => {
      router.push({  name: "ticket" });
    }

    return {
      previousStep,
      nextStep,
      formSubmit,
      state,
      resetForm,
      tableHeader,
      tableHeaderSite,
      formatDate,
      handleChangeQte,
      selectRows,
      refreshSites,
      capitalize,
      upperCase,
      testFirstLieu,
      changeSocieteSite,
      handleClick,
      tableHeaderStaging,
      handleChangeUpload,
      openUpload,
      deleteFile,
      recalculStock,
      createTicket,
      retourTicket
    };

  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
